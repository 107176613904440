<template>
  <div id="test">
    <div>
      <a-button type="primary" @click="showModal">
        Modal
      </a-button>
      <a-button type="primary" @click="routerTo">
        点我
      </a-button>
      <a-modal v-model="visible" title="Modal" ok-text="确认" cancel-text="取消" @ok="hideModal">
        <p>{{ username }}</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <a-button type="primary" @click="updateModal">
          你点我啊
        </a-button>
      </a-modal>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Test',
  components: {},
  data() {
    return {
      visible: false,
      username: ''
    };
  },
  mounted () {
    this.username = this.$store.state.username
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    hideModal() {
      this.visible = false;
    },
    confirm() {
      this.$confirm({
        title: 'Confirm',
        content: 'Bla bla ...',
        okText: '确认',
        cancelText: '取消',
      });
    },
    updateModal () {
      this.$store.commit('updateUsername');
      console.log(this.$store.state.username);
      this.username = this.$store.state.username;
    }
  }
}
</script>


<style>
.test {
  width: 100%;
  height: 100%;
}
</style>
