<template>
  <div class="nav">
    <a-menu mode="horizontal">
      <a-menu-item key="uploader" v-if="userinfo.userLevel === 5 && userinfo.loginStatu === 1" @click="showModal"> <a-icon type="thunderbolt" />石猴出世 </a-menu-item>
      <a-sub-menu v-for="navList in nav" :key="navList.id" :index="navList.id">
        <span slot="title" class="submenu-title-wrapper">
          <a-icon v-if="navList.navTitle === '拜师学艺'" type="shake" />
          <a-icon v-else-if="navList.navTitle === '如来说法'" type="read" />
          <a-icon v-else-if="navList.navTitle === '大闹天宫'" type="alert" />
          <i v-else-if="navList.navTitle === '真假猴王'" class="el-icon-magic-stick"></i>
          <a-icon v-else-if="navList.navTitle === '求仙得道'" type="cloud" />
          <a-icon v-else type="coffee" />
          {{ navList.navTitle }}
        </span>
        <a-menu-item-group v-if="navList.isHaveQD != 0" title="前端">
          <template v-for="navSecList in navSec">
            <a-menu-item :key="navSecList.id" v-if="navSecList.belongFirNav == navList.id && navSecList.isHaveQD == 1" @click="paramsRoute('TheWayToLearn', navSecList.navTitle)">
              {{ navSecList.navTitle }}
            </a-menu-item>
          </template>
          <a-sub-menu>
            <span slot="title" class="submenu-title-wrapper">查看更多</span>
            <template v-for="navThirdList in navThird">
              <a-menu-item :key="navThirdList.id" v-if="navThirdList.belongFirNav == navList.id && navThirdList.isHaveQD == 1" @click="paramsRoute('TheWayToLearn', navThirdList.navTitle)">
                {{ navThirdList.navTitle }}
              </a-menu-item>
            </template>
          </a-sub-menu>
        </a-menu-item-group>
        <a-menu-item-group v-if="navList.isHaveHD != 0" title="后端">
          <template v-for="navSecList in navSec">
            <a-menu-item :key="navSecList.id" v-if="navSecList.belongFirNav == navList.id && navSecList.isHaveHD == 1" @click="paramsRoute('TheWayToLearn', navSecList.navTitle)">
              {{ navSecList.navTitle }}
            </a-menu-item>
          </template>
          <a-sub-menu>
            <span slot="title" class="submenu-title-wrapper">查看更多</span>
            <template v-for="navThirdList in navThird">
              <a-menu-item :key="navThirdList.id" v-if="navThirdList.belongFirNav == navList.id && navThirdList.isHaveHD == 1" @click="paramsRoute('TheWayToLearn', navThirdList.navTitle)">
                {{ navThirdList.navTitle }}
              </a-menu-item>
            </template>
          </a-sub-menu>
        </a-menu-item-group>
        <a-menu-item-group v-if="navList.isHaveSQL != 0" title="数据库">
          <template v-for="navSecList in navSec">
            <a-menu-item :key="navSecList.id" v-if="navSecList.belongFirNav == navList.id && navSecList.isHaveSQL == 1" @click="paramsRoute('TheWayToLearn', navSecList.navTitle)">
              {{ navSecList.navTitle }}
            </a-menu-item>
          </template>
          <a-sub-menu>
            <span slot="title" class="submenu-title-wrapper">查看更多</span>
            <template v-for="navThirdList in navThird">
              <a-menu-item :key="navThirdList.id" v-if="navThirdList.belongFirNav == navList.id && navThirdList.isHaveSQL == 1" @click="paramsRoute('TheWayToLearn', navThirdList.navTitle)">
                {{ navThirdList.navTitle }}
              </a-menu-item>
            </template>
          </a-sub-menu>
        </a-menu-item-group>
        <a-menu-item-group v-if="navList.isHaveOther != 0" title="其他">
          <template v-for="navSecList in navSec">
            <a-menu-item :key="navSecList.id" v-if="navSecList.belongFirNav == navList.id && navSecList.isHaveOther == 1" @click="paramsRoute('TheWayToLearn', navSecList.navTitle)">
              {{ navSecList.navTitle }}
            </a-menu-item>
          </template>
          <a-sub-menu>
            <span slot="title" class="submenu-title-wrapper">查看更多</span>
            <template v-for="navThirdList in navThird">
              <a-menu-item :key="navThirdList.id" v-if="navThirdList.belongFirNav == navList.id && navThirdList.isHaveOther == 1" @click="paramsRoute('TheWayToLearn', navThirdList.navTitle)">
                {{ navThirdList.navTitle }}
              </a-menu-item>
            </template>
          </a-sub-menu>
        </a-menu-item-group>
      </a-sub-menu>
      <a-menu-item v-for="noSec in navListNoSec" :key="noSec.id" :index="noSec.id" key="uploader" @click="noParamsRoute(noSec.navName)">
        <i v-if="noSec.navTitle === '真假猴王'" :class="noSec.navIcon"></i>
        <a-icon v-else :type="noSec.navIcon" />
        {{noSec.navTitle}}
      </a-menu-item>
      <a-menu-item key="login" v-if="userinfo.loginStatu === 0" @click="showModalLogin"> 登录 / 注册 </a-menu-item>
      <a-sub-menu key="relax" v-if="userinfo.userLevel === 5 && userinfo.loginStatu === 1">
        <span slot="title" class="submenu-title-wrapper">
          <a-icon type="coffee" /> 劳逸结合
        </span>
        <a-menu-item key="record">
          日常记录
        </a-menu-item>
        <a-menu-item key="remind">
          提醒事项
        </a-menu-item>
        <a-menu-item key="mandi">
          蜗牛慢递
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
    <a-modal
      v-model="visible"
      :title="tempFormTitle"
      centered
      @ok="submitForm"
      @cancel="handleClose('form')"
    >
      <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="80px" class="demo-dynamic addForm">
        <el-form-item label="所属模块">
          <el-select v-model="dynamicValidateForm.modeler" placeholder="请选择所属模块" style="width: 79%;" @change="changeModel">
            <el-option v-for="(modeler, index) in modelList" :key="index" :label="modeler.modelName" :value="modeler.modelValue"></el-option>
            <el-option label="--- 更多知识逐步学习中 ---" value="noValue" disabled></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级模块">
          <el-select v-model="dynamicValidateForm.secModel" placeholder="请选择二级模块" style="width: 79%;">
            <el-option v-for="(modeler, index) in secModelList" :key="index" :label="modeler.modelName" :value="modeler.modelValue"></el-option>
            <el-option label="--- 更多知识逐步学习中 ---" value="noValue" disabled></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="展示图标">
          <el-select v-model="dynamicValidateForm.iconer" placeholder="请选择图标" style="width: 79%;">
            <el-option v-for="(icone, index) in iconList" :key="index" :label="icone.iconName" :value="icone.iconValue">
              <a-icon :type="icone.iconValue" theme="twoTone" />
              <span style="padding-left: 10px;">{{ icone.iconName }}</span>
            </el-option>
            <el-option label="--- 更多图标逐步完善中 ---" value="noValue" disabled></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容标题">
          <el-input
            style="width: 79%;"
            type="textarea"
            autosize
            placeholder="请输入标题"
            v-model="dynamicValidateForm.contentTitle">
          </el-input>
        </el-form-item>
        <el-form-item label="作者感言">
          <el-input
            style="width: 79%;"
            type="textarea"
            autosize
            placeholder="请输入你的感言"
            v-model="dynamicValidateForm.editorThink">
          </el-input>
        </el-form-item>
        <el-form-item label="知识难度">
          <el-rate
            style="height: 40px; line-height: 40px; padding: 8px 0;"
            v-model="dynamicValidateForm.difficulter"
            :icon-classes="['icon-rate-face-3', 'icon-rate-face-2', 'icon-rate-face-1']"
            void-icon-class="icon-rate-face-off"
            :colors="['#FF9900', '#F7BA2A', '#99A9BF']">
          </el-rate>
        </el-form-item>
        <el-form-item
          v-for="(domain, index) in dynamicValidateForm.domains"
          :label="domain.addType"
          :key="domain.key"
          :prop="'domains.' + index + '.value'"
          :rules="{
            required: true, message: '文本框不能为空', trigger: 'blur'
          }"
        >
          <el-input autosize type="textarea" :rows="1" style="width: 79%; min-height: 36px;" v-model="domain.value"></el-input>
          <el-button @click.prevent="removeDomain(domain)" style="height: 33px; padding: 6px 20px; margin-left: 1%;">删除</el-button>
        </el-form-item>
        <div>
          <a-button type="dashed" style="width: 20%;" @click="addDomain('文本内容')">新增文本</a-button>
          <a-button type="dashed" style="width: 20%; margin-left: 5%;" @click="addDomain('图片链接')">新增图片</a-button>
          <a-button type="dashed" style="width: 20%; margin-left: 5%;" @click="addDomain('视频链接')">新增视频</a-button>
          <a-button type="dashed" style="width: 20%; margin-left: 5%;" @click="addDomain('文件链接')">新增文件</a-button>
        </div>
      </el-form>
    </a-modal>
    <a-modal
      v-model="fileVisible"
      :title="fileFormTitle"
      centered
      :width="500"
      cancelText="已有文件无需上传"
      @cancel="handleClose('file')"
      okText="我已完成上传"
      @ok="handleClose('file')"
    >
      <el-upload
        :file-list="fileList"
        :disabled="fileDis"
        class="upload-demo"
        drag
        :action="uploadUrl"
        :on-success="uploadSuccess"
        >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">请注意，一次只可上传一个文件，多次上传仅保留最后一次上传结果</div>
        <div class="el-upload__tip" slot="tip">请手动复制此链接：{{tempLink}}</div>
      </el-upload>
    </a-modal>
    <a-modal
      v-model="loginVisible"
      :title="loginRegTitle"
      centered
      :width="500"
      cancelText="取消"
      @cancel="handleClose('loginRegForm')"
      :okText="loginRegTitle"
      @ok="loginRegBtn"
    >
      <el-form v-if="loginOrReg === '登录' || loginOrReg === '账号密码登录'" :model="loginForm" ref="loginForm" label-width="100px" class="demo-ruleForm loginFormAni">
        <el-form-item label="账号" prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="psd">
          <el-input v-model="loginForm.psd" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-link type="primary" style="margin-left: 100px;" @click="changeLoginOrReg('注册')">注册</el-link>
        <el-link type="primary" style="margin-left: 20px;" @click="changeLoginOrReg('照妖镜')">扫码登录</el-link>
        <el-link type="primary" style="margin-left: 20px;">忘记密码</el-link>
      </el-form>
      <el-form v-else-if="loginOrReg === '注册'" :model="signForm" ref="signForm" label-width="100px" class="demo-ruleForm loginFormAni">
        <el-form-item label="账号" prop="username">
          <el-input v-model="signForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="psd">
          <el-input v-model="signForm.psd" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePsd">
          <el-input v-model="signForm.rePsd" type="password" placeholder="请再次确认您的密码"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phoneNum">
          <el-input v-model="signForm.phoneNum" placeholder="请输入手机号" style="width: 65%;" @input="phoneNumRegExp(signForm.phoneNum)"></el-input>
          <el-button type="primary" plain style="width: 35%; float: right;" :disabled="verifyDisabled" @click="queryVerify(signForm.phoneNum)">{{ tempVerifyText }}</el-button>
        </el-form-item>
        <el-form-item label="验证码" prop="messageVerify">
          <el-input v-model="signForm.messageVerify" placeholder="请输入验证码"></el-input>
        </el-form-item>
        <el-link type="primary" style="margin-left: 100px;" @click="changeLoginOrReg('登录')">登录</el-link>
        <el-link type="primary" style="margin-left: 20px;">忘记密码</el-link>
      </el-form>
      <el-form v-else-if="loginOrReg === '照妖镜'" :model="signForm" ref="signForm" label-width="100px" class="demo-ruleForm loginFormAni">
        <vue-qr text="signInIsOK" colorDark="#409EFF" colorLight="white" :logoSrc="vueQrCode.logoSrc" :size="200"></vue-qr>
        <el-link type="primary" style="margin-left: 100px;" @click="changeLoginOrReg('账号密码登录')">账号密码登录</el-link>
      </el-form>
      <el-form v-else class="demo-ruleForm loginFormAni">
        <h1>非法操作！</h1>
      </el-form>
    </a-modal>
  </div>
</template>

<script>
import $ from 'jquery'
import VueQr from 'vue-qr'
import logoPic from '@/assets/logo.png'

export default {
  name: 'Nav',
  components: {
    VueQr
  },
  data () {
    return {
      userinfo: {
        loginStatu: 0
      },
      // 控制是否展示文件上传列表
      fileList: [],
      // 文件上传地址（需将图片视频文件作区分）
      uploadUrl: this.$common.upload,
      // 控制上传弹窗
      visible: false,
      // 控制登录 / 注册弹窗
      loginVisible: false,
      // 当前注册 / 登录弹窗的状态
      loginOrReg: '登录',
      // 控制文件上传弹窗
      fileVisible: false,
      // 文件上传临时链接存放
      tempLink: '',
      // 控制文件上传的按钮，暂时为 false
      fileDis: false,
      nav: [],
      navSec: [],
      navThird: [],
      // 不存在二级导航栏但是后面可能会存在的一级导航栏
      navListNoSec: [],
      // 知识上传弹窗标题
      tempFormTitle: '告别大唐',
      // 文件上传弹窗标题
      fileFormTitle: '',
      // 登录 / 注册弹窗标题
      loginRegTitle: '登录',
      // 一级模块选项
      modelList: [{
        modelName: '前端',
        modelValue: '前端'
      }, {
        modelName: '后端',
        modelValue: '后端'
      }, {
        modelName: '数据库',
        modelValue: '数据库'
      }, {
        modelName: '其他',
        modelValue: '其他'
      }],
      // 二级模块选项
      secModelList: [],
      // 图标选项
      iconList: [{
        iconName: 'like',
        iconValue: 'like'
      }, {
        iconName: 'smile',
        iconValue: 'smile'
      }, {
        iconName: 'star',
        iconValue: 'star'
      }, {
        iconName: 'question-circle',
        iconValue: 'question-circle'
      }, {
        iconName: 'heart',
        iconValue: 'heart'
      }, {
        iconName: 'meh',
        iconValue: 'meh'
      }, {
        iconName: 'info-circle',
        iconValue: 'info-circle'
      }, {
        iconName: 'exclamation-circle',
        iconValue: 'exclamation-circle'
      }, {
        iconName: 'clock-circle',
        iconValue: 'clock-circle'
      }, {
        iconName: 'warning',
        iconValue: 'warning'
      }, {
        iconName: 'bulb',
        iconValue: 'bulb'
      }, {
        iconName: 'code',
        iconValue: 'code'
      }, {
        iconName: 'frown',
        iconValue: 'frown'
      }, {
        iconName: 'tool',
        iconValue: 'tool'
      }, {
        iconName: 'thunderbolt',
        iconValue: 'thunderbolt'
      }],
      // 以下为添加内容数据
      dynamicValidateForm: {
        modeler: '',
        secModel: '',
        iconer: '',
        contentTitle: '',
        difficulter: null,
        editorThink: '',
        domains: [],
      },
      // 以上为添加内容数据
      // 以下为控制新增的输入框的索引
      inputIndex: 3,
      // 以下为评分所需数据
      iconClasses: ['icon-rate-face-1', 'icon-rate-face-2', 'icon-rate-face-3'],
      // 以上为评分所需数据
      // 登录表单数据
      loginForm: {
        username: '',
        psd: ''
      },
      // 注册表单数据
      signForm: {
        username: '',
        psd: '',
        rePsd: '',
        phoneNum: '',
        messageVerify: ''
      },
      // 控制获取短信验证码的按钮
      verifyDisabled: true,
      // 获取验证码按钮的文本临时存储
      tempVerifyText: '获取验证码',
      // 二维码数据存储对象
      vueQrCode: {
        logoSrc: logoPic
      }
    }
  },
  mounted () {
    this.queryNav()
    this.queryUserinfo()
  },
  methods: {
    queryUserinfo () {
      let userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
      // console.log(userinfo);
      if (userinfo != null) {
        this.userinfo = userinfo;
      }
    },
    queryNav () {
      let that = this
      $.ajax({
        url: this.$common.nav,
        type: 'GET',
        dataType: 'text',
        contentType: 'application/x-www-form-urlencoded',
        success: function (res) {
          // let result = JSON.parse(res)
          let result = JSON.parse(res).message
          console.log(result)
          for (let i = 0; i < result.length; i++) {
            // 注入路由规则的对象
            let objNav = {
              path: result[i].navUrl,
              name: result[i].navName,
              // 此处由于 WebPack 的原因不能直接在 require 中写入变量，需要使用模板字符串，并且在变量前拼接部分目录，如果拼接一个不行就两个，详情参考 https://www.jb51.net/article/191769.htm
              component : resolve => require([`@/components/${result[i].componentName}.vue`], resolve)
            }
            that.$router.addRoute(objNav)
            // 首页导航栏展示的路由名称的完整对象，一级导航存入一级导航数组，二级导航存入二级导航数组
            if (result[i].navLevel == 1 && (result[i].isHaveQD != 0 && result[i].isHaveHD != 0 && result[i].isHaveSQL != 0 && result[i].isHaveOther != 0)) {
              that.nav[i] = result[i]
            } else if (result[i].navLevel == 1 && result[i].isHaveQD == 0 && result[i].isHaveHD == 0 && result[i].isHaveSQL == 0 && result[i].isHaveOther == 0) {
              that.navListNoSec[i] = result[i];
            } else if (result[i].navLevel == 2) {
              // 此处 i 进来的时候是从 4 开始，所以会直接给 navSec[4] 赋值，造成前面三个为空
              // 新增目录时目录后的 ID 应为连续的，否则会报 id undefined 错误
              that.navSec[i] = result[i]
            } else {
              that.navThird[i] = result[i]
            }
          }
          that.nav = that.nav.splice(0, that.nav.length)
          that.navListNoSec = that.navListNoSec.splice(that.nav.length, that.navListNoSec.length)
          that.navSec = that.navSec.splice(that.nav.length + that.navListNoSec.length, that.navSec.length)
          console.log(that.navSec)
          that.navThird = that.navThird.splice(that.nav.length + that.navListNoSec.length + that.navSec.length, that.navThird.length)
          console.log(that.navThird)
        },
        error: function () {
          console.log('退出登录失败，无法连接到服务器，请稍后再试！')
        }
      })
    },
    showModal () {
      this.visible = true;
    },
    showModalLogin () {
      this.loginVisible = true;
    },
    // 以下为添加产品抽屉方法
    removeDomain (item) {
      var index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
      }
    },
    addDomain (addType) {
      if (addType !== '文本内容') {
        this.fileVisible = true;
      }
      if (addType === '图片链接') {
        this.fileFormTitle = '请上传图片，并复制生成的链接';
        this.uploadUrl = this.$common.uploadPicture;
      } else if (addType === '视频链接') {
        this.fileFormTitle = '请上传视频，并复制生成的链接';
        this.uploadUrl = this.$common.uploadVideo;
      } else {
        this.fileFormTitle = '请上传文件，并复制生成的链接';
        this.uploadUrl = this.$common.uploadFile;
      }
      this.dynamicValidateForm.domains.push({
        value: '',
        // addType 表示增加的类型，前端再次加载时依靠此属性判定调用文本标签还是图片标签
        addType: addType,
        // key: Date.now()
        key: ++this.inputIndex
      })
    },
    submitForm (done) {
      if (this.loading) {
        return
      }
      this.$confirm('确定要关闭表单吗？关闭前请确认填写的数据是否提交！')
        .then(_ => {
          let that = this;
          $.ajax({
            url: this.$common.uploadLearn,
            type: 'GET',
            data: {
              modeler: this.dynamicValidateForm.modeler,
              secModel: this.dynamicValidateForm.secModel,
              iconer: this.dynamicValidateForm.iconer,
              contentTitle: this.dynamicValidateForm.contentTitle,
              difficulter: this.dynamicValidateForm.difficulter,
              editorThink: this.dynamicValidateForm.editorThink,
              learnContent: JSON.stringify(this.dynamicValidateForm.domains)
            },
            dataType: 'text',
            contentType: 'application/x-www-form-urlencoded',
            success: function (res) {
              // let result = JSON.parse(res)
              let result = JSON.parse(res)
              console.log(result)
              that.visible = false;
              that.resetForm();
              if (result.status === 200) {
                that.successMsg(result.message)
              } else {
                that.warnMsg(result.message)
              }
            },
            error: function (res) {
              that.errorMsg(res)
            }
          })
        })
        .catch(_ => {})
    },
    handleClose (val) {
      if (val === 'form') {
        this.visible = false;
        this.resetForm();
      } else if (val === 'loginForm') {
        this.loginVisible = false;
        this.resetForm();
      } else {
        this.fileVisible = false;
        this.resetFile();
      }
    },
    // 以上为添加产品抽屉方法
    changeModel (e) {
      if (e === '前端') {
        let arr = [{
          modelName: 'HTML',
          modelValue: 'HTML'
        }, {
          modelName: 'CSS',
          modelValue: 'CSS'
        }, {
          modelName: 'JavaScript',
          modelValue: 'JavaScript'
        }, {
          modelName: 'jQuery',
          modelValue: 'jQuery'
        }, {
          modelName: 'Vue.js',
          modelValue: 'Vue.js'
        }, {
          modelName: 'Element-UI',
          modelValue: 'Element-UI'
        }, {
          modelName: 'Ant-Design-Vue',
          modelValue: 'Ant-Design-Vue'
        }, {
          modelName: 'Angular.js',
          modelValue: 'Angular.js'
        }, {
          modelName: 'React.js',
          modelValue: 'React.js'
        }]
        this.secModelList = arr;
      } else if (e === '后端') {
        let arr = [{
          modelName: 'Node.js',
          modelValue: 'Node.js'
        }, {
          modelName: 'PHP',
          modelValue: 'PHP'
        }, {
          modelName: 'Nginx',
          modelValue: 'Nginx'
        }, {
          modelName: 'Apache',
          modelValue: 'Apache'
        }, {
          modelName: 'Java',
          modelValue: 'Java'
        }, {
          modelName: 'Python',
          modelValue: 'Python'
        }, {
          modelName: 'Go',
          modelValue: 'Go'
        }]
        this.secModelList = arr;
      } else if (e === '数据库') {
        let arr = [{
          modelName: 'MySQL',
          modelValue: 'MySQL'
        }, {
          modelName: 'SQL-Server',
          modelValue: 'SQL-Server'
        }, {
          modelName: 'MongoDB',
          modelValue: 'MongoDB'
        }, {
          modelName: 'Oracle',
          modelValue: 'Oracle'
        }]
        this.secModelList = arr;
      } else {
        let arr = [{
          modelName: '计算机网络技术',
          modelValue: '计算机网络技术'
        }, {
          modelName: '云服务部署',
          modelValue: '云服务部署'
        }]
        this.secModelList = arr;
      }
    },
    resetForm () {
      this.dynamicValidateForm.modeler = '';
      this.dynamicValidateForm.secModel = '';
      this.dynamicValidateForm.iconer = '';
      this.dynamicValidateForm.contentTitle = '';
      this.dynamicValidateForm.difficulter = null;
      this.dynamicValidateForm.editorThink = '';
      this.secModelList = [];
      this.dynamicValidateForm.domains = [];
      this.loginForm.username = '';
      this.loginForm.psd = '';
      this.signForm.username = '';
      this.signForm.psd = '';
      this.signForm.rePsd = '';
      this.signForm.phoneNum = '';
      this.signForm.messageVerify = '';
    },
    // 清理生成的临时链接和文件列表
    resetFile () {
      this.tempLink = '';
      this.fileList = [];
    },
    // 文件上传成功的钩子，返回的 fileList 是对象，得用 push 插入数组，不能直接赋值
    uploadSuccess (res, fileList) {
      this.tempLink = res.fileCosUrl;
      this.fileList.push(fileList);
    },
    // 成功提示
    successMsg (msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'success'
      });
    },
    // 警告提示
    warnMsg (msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'warning'
      });
    },
    // 错误提示
    errorMsg (msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'error'
      });
    },
    // 无参数跳转
    noParamsRoute (routeName) {
      this.$router.push({name: routeName})
    },
    // 带参数跳转
    paramsRoute (routeName, kindName) {
      // 方法一（用 back 回退）
      let that = this;
      let webUrl = document.location.href;
      let reg = new RegExp(routeName,"g");
      if (reg.test(webUrl)) {
        this.$router.back();
        setTimeout(function () {
          that.$router.push(routeName + '/' + kindName)
        }, 10)
      } else {
        this.$router.push(routeName + '/' + kindName)
      }

      // 方法二（刷新整页）
      // let webUrl = document.location.href;
      // let reg = new RegExp(routeName,"g");
      // if (reg.test(webUrl)) {
      //   // this.$router.push(kindName)
      //   document.location.href = 'http://localhost:8080/TheWayToLearn/' + kindName;
      // } else {
      //   this.$router.push(routeName + '/' + kindName)
      // }

      // 方法三（暂未实现）
      // let routerName = routeName.replace('/', '');
      // this.$router.push({name: routerName, params: {kindname: kindName}})
    },
    // 切换登录 / 注册弹窗的状态
    changeLoginOrReg (val) {
      this.loginRegTitle = val;
      this.loginOrReg = val;
    },
    // 手机号正则验证
    phoneNumRegExp (phoneNumber) {
      let reg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
      if (reg.test(phoneNumber)) {
        this.verifyDisabled = false;
      } else {
        this.verifyDisabled = true;
      }
    },
    // 请求验证码
    queryVerify (phoneNumber) {
      let that = this;
      $.ajax({
        url: this.$common.queryVerify,
        type: 'GET',
        data: {
          phoneNum: phoneNumber
        },
        dataType: 'text',
        contentType: 'application/x-www-form-urlencoded',
        success: function (res) {
          let result = JSON.parse(res);
          console.log(result);
          if (result.status == 200) {
            let thater = that;
            that.verifyDisabled = true;
            let countDown = 60;
            let t = setInterval(function () {
              if (countDown <= 0) {
                thater.phoneNumRegExp(thater.signForm.phoneNum);
                thater.tempVerifyText = '获取验证码'
                clearInterval(t);
              } else {
                countDown --;
                thater.tempVerifyText = countDown + 's重新获取';
              }
            }, 1000)
          }
        },
        error: function () {
          console.log('退出登录失败，无法连接到服务器，请稍后再试！')
        }
      })
    },
    // 登录或注册按钮
    loginRegBtn () {
      let that = this;
      let url = '';
      let userData = {};
      if (this.loginRegTitle === '登录' || this.loginRegTitle === '账号密码登录') {
        url = this.$common.login;
        userData = {
          username: this.loginForm.username,
          psd: this.loginForm.psd
        };
      } else if (this.loginRegTitle === '注册') {
        // 校验两次密码是否一致
        if (this.signForm.psd === this.signForm.rePsd) {
          url = this.$common.signin;
          userData = {
            username: this.signForm.username,
            psd: this.signForm.psd,
            phoneNum: this.signForm.phoneNum,
            messageVerify: this.signForm.messageVerify
          }
        } else {
          this.warnMsg('两次密码不一致！');
          return;
        }
        
      } else {
        console.log('状态不正确或者您当前为扫码登录！');
      }
      $.ajax({
        url: url,
        type: 'GET',
        data: userData,
        dataType: 'text',
        contentType: 'application/x-www-form-urlencoded',
        success: function (res) {
          let result = JSON.parse(res);
          console.log(result);
          if (result.status == 200) {
            that.resetForm();
            that.successMsg(result.message);
            if (that.loginRegTitle === '注册') {
              that.changeLoginOrReg('登录');
            } else {
              that.loginVisible = false;
              let userinfo = result.userinfo[0];
              userinfo.loginStatu = 1;
              sessionStorage.setItem('userinfo', JSON.stringify(userinfo));
              window.location.reload();
            }
          } else {
            that.errorMsg(result.message);
          }
        },
        error: function () {
          console.log('Error!!!')
        }
      })
    },
    // 请求单独某一分类的知识点（此处仅将分类传给拜师学艺页面）
    queryKindKnowledge (kindName) {
      $.ajax({
        url: this.$common.queryKindKnowledge,
        type: 'GET',
        data: {
          phoneNum: phoneNumber
        },
        dataType: 'text',
        contentType: 'application/x-www-form-urlencoded',
        success: function (res) {
          let result = JSON.parse(res);
          console.log(result);
          if (result.status == 200) {
            let thater = that;
            that.verifyDisabled = true;
            let countDown = 60;
            let t = setInterval(function () {
              if (countDown <= 0) {
                thater.phoneNumRegExp(thater.signForm.phoneNum);
                thater.tempVerifyText = '获取验证码'
                clearInterval(t);
              } else {
                countDown --;
                thater.tempVerifyText = countDown + 's重新获取';
              }
            }, 1000)
          }
        },
        error: function () {
          console.log('退出登录失败，无法连接到服务器，请稍后再试！')
        }
      })
    }
  }
}
</script>

<style scoped>
.nav{
  width: 100%;
  height: 46px;
}
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}
.addForm{
  height: 410px;
  overflow-y: scroll;
}
</style>
