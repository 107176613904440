<template>
  <div id="app">
    <Nav></Nav>
    <router-view/>
  </div>
</template>

<script>
import Nav from './components/public/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav
  }
}
</script>


<style>
@font-face {
  font-family: 'sjt';
  /* 字体跨域需在 CDN HTTP响应头配置处设置 http response 头部返回 Access-Control-Allow-Origin */
  src: url('https://blogapi.lxlgyy.cn/webfonts/sjt.ttf') format('truetype');
}
*{
  margin: 0;
  padding: 0;
  font-family: 'sjt', '华文行楷', Avenir, Palace Script MT, Helvetica, Arial, sans-serif;
}
html, body, #app {
  width: 100%;
  height: 100%;
  /* filter: grayscale(1); */
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}
/* 设置滑块 */
::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
</style>
