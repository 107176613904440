<template>
  <div class="hello">
    <div class="searchLearn">
      <el-autocomplete
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        placeholder="有事启奏，无事退朝！"
        @select="handleSelect"
        style="width: 60%;"
        @keypress.enter.native="clickSearch"
      ></el-autocomplete>
      <el-button type="primary" @click="clickSearch">搜索</el-button>
    </div>
    <div class="content">
      <a-timeline class="timeLine" mode="left" pending="更多知识正在学习中~">
        <a-timeline-item v-for="(learner, index) in learnList" :key="index">
          <a-icon slot="dot" :type="learner.learnicon" theme="twoTone" two-tone-color="#1890ff" style="font-size: 20px;" />
          <span>
            创建于 {{ learner.publishtime }}
            <el-rate
              style="padding-top: 3px;"
              disabled
              v-model="learner.learndifficult"
              :icon-classes="['icon-rate-face-3', 'icon-rate-face-2', 'icon-rate-face-1']"
              void-icon-class="icon-rate-face-off"
              :colors="['#FF9900', '#F7BA2A', '#99A9BF']">
            </el-rate>
          </span>
          <div style="margin-top: 10px;">
            <a-card :loading="loading" :title="learner.learntitle" class="textOverflow">
              <div class="changeLearn" @click="changeLearn(learner)">
                {{ learner.learncontent[0].value }}
              </div>
            </a-card>
          </div>
        </a-timeline-item>
      </a-timeline>
      <div class="learnContent">
        <h1>
          <strong>{{ tempLearn.learntitle }}</strong>
        </h1>
        <h3 style="color: #5e6d82; margin-bottom: 45px;">
          <span style="float: left;">创建于 {{ tempLearn.publishtime }}</span>
          <el-rate
            style="padding-left: 10px; padding-top: 3px; float: left;"
            disabled
            v-model="tempLearn.learndifficult"
            :icon-classes="['icon-rate-face-3', 'icon-rate-face-2', 'icon-rate-face-1']"
            void-icon-class="icon-rate-face-off"
            :colors="['#FF9900', '#F7BA2A', '#99A9BF']">
          </el-rate>
        </h3>
        <div style="width: 100%; float: left; text-indent: 2em; margin-top: 10px; font-family: '华文行楷'; font-size: 25px;" v-for="(learncontent, index) in tempLearn.learncontent" :key="index">
          <div v-if="learncontent.addType === '文本内容'" style="text-align: justify;">
            <span v-html="learncontent.value"></span>
          </div>
          <div v-else-if="learncontent.addType === '图片链接'" style="width: 85%; margin-top: 25px; margin-bottom: 50px;">
            <!-- <img :src="learncontent.value" :alt="learncontent.key" @click="viewPic(learncontent.value)"> -->
            <div class="demo-image__preview" style="width: 85%;">
              <el-image
                :src="learncontent.value" 
                :preview-src-list="tempPictureList">
              </el-image>
            </div>
          </div>
          <div v-else-if="learncontent.addType === '视频链接'" style="width: 100%; margin-top: 25px; margin-bottom: 50px;">
            <video :src="learncontent.value" controls style="width: 85%;"></video>
          </div>
          <div v-else>
            <a :href="learncontent.value" download="download">点击下载</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'HelloWorld',
  components: {},
  data () {
    return {
      // 控制 card 的 loading
      loading: true,
      learnList: [],
      // 右侧展示 learnList 第一位
      tempLearn: {},
      // 右侧展示 learnList 第一位的所有图片列表
      tempPictureList: [],
      // 临时放大的图片的链接
      tempViewPic: '',
      // 临时评分
      rateVal: 3,
      // 图片放大倍数，遮罩层关闭后重置为 1
      counter: 1,
      // 远程搜索框数据
      serverLearnList: [],
      state: '',
      // 失去焦点出发搜索时存放的完整结果数据
      // blurSearchItem: []
    }
  },
  mounted () {
    this.queryLearn()
  },
  methods: {
    // 请求知识列表
    queryLearn () {
      let that = this;
      $.ajax({
        url: this.$common.queryLearn,
        type: 'GET',
        dataType: 'text',
        contentType: 'application/x-www-form-urlencoded',
        success: function (res) {
          // let result = JSON.parse(res)
          that.loading = false
          let result = JSON.parse(res)
          console.log(result)
          // let reg = /\n/g;
          if (result.status == 200) {
            for (let i = 0; i < result.message.length; i ++) {
              result.message[i].learncontent = JSON.parse(result.message[i].learncontent)
            }
            that.learnList = result.message;
            that.tempLearn = result.message[0];
            for (let j = 0; j < result.message[0].learncontent.length; j ++) {
              if (result.message[0].learncontent[j].addType === '图片链接') {
                that.tempPictureList.push(result.message[0].learncontent[j].value)
              } else {
                // result.message[1].learncontent[j].value = result.message[1].learncontent[j].value.replace(reg, '<br />');
                continue;
              }
            }
            console.log(result.message[0])
          } else if (result.status == 12001) {
            that.errorMsg(result.message);
          } else {
            that.errorMsg('无法连接到服务器，请稍后再试！');
          }
        },
        error: function () {
          console.log('退出登录失败，无法连接到服务器，请稍后再试！')
        }
      })
    },
    // 切换右侧展示知识点事件
    changeLearn (val) {
      // console.log(val)
      this.tempPictureList = [];
      for (let i = 0; i < val.learncontent.length; i ++) {
        if (val.learncontent[i].addType === '图片链接') {
          this.tempPictureList.push(val.learncontent[i].value)
        }
      }
      this.tempLearn = val;
    },
    // 放大图片的方法
    viewPic (val) {
      this.tempViewPic = val;
      document.getElementsByClassName('imgView')[0].style.display = 'block'
      document.getElementsByClassName('fullscreen')[0].style.display = 'block'
      document.getElementsByClassName('contentImg')[0].style.transform = 'scale(1)'
    },
    // 取消遮罩层和图片放大
    hideScreen () {
      document.getElementsByClassName('imgView')[0].style.display = 'none'
      document.getElementsByClassName('fullscreen')[0].style.display = 'none'
    },
    // 鼠标滚轮事件
    pageUpOrDown (e) {
      let ele = document.getElementsByClassName('contentImg')[0];
      if (e.deltaY > 0) {
        if (this.counter < 0.5) {
          this.warnMsg('再小就看不见咯');
          return;
        } else {
          this.counter = this.counter - 0.1;
          console.log('正在向下');
          ele.style.transform = 'scale(' + this.counter + ')';
        }
      } else {
        if (this.counter > 5) {
          this.warnMsg('屏幕装不下咯');
          return;
        } else {
          console.log('正在向上')
          this.counter = this.counter + 0.1;
          ele.style.transform = 'scale(' + this.counter + ')';
        }
      }
    },
    // 成功提示
    successMsg (msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'success'
      });
    },
    // 警告提示
    warnMsg (msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'warning'
      });
    },
    // 错误提示
    errorMsg (msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'error'
      });
    },
    // 远程搜索方法
    loadAll() {
      return [
        { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
        { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
        { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
        { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
        { "value": "胖仙女纸杯蛋糕（上海凌空店）", "address": "上海市长宁区金钟路968号1幢18号楼一层商铺18-101" },
        { "value": "贡茶", "address": "上海市长宁区金钟路633号" },
        { "value": "豪大大香鸡排超级奶爸", "address": "上海市嘉定区曹安公路曹安路1685号" },
        { "value": "茶芝兰（奶茶，手抓饼）", "address": "上海市普陀区同普路1435号" },
        { "value": "十二泷町", "address": "上海市北翟路1444弄81号B幢-107" },
        { "value": "星移浓缩咖啡", "address": "上海市嘉定区新郁路817号" },
        { "value": "阿姨奶茶/豪大大", "address": "嘉定区曹安路1611号" },
        { "value": "新麦甜四季甜品炸鸡", "address": "嘉定区曹安公路2383弄55号" },
        { "value": "Monica摩托主题咖啡店", "address": "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F" },
        { "value": "浮生若茶（凌空soho店）", "address": "上海长宁区金钟路968号9号楼地下一层" },
        { "value": "NONO JUICE  鲜榨果汁", "address": "上海市长宁区天山西路119号" },
        { "value": "CoCo都可(北新泾店）", "address": "上海市长宁区仙霞西路" },
        { "value": "快乐柠檬（神州智慧店）", "address": "上海市长宁区天山西路567号1层R117号店铺" },
        { "value": "Merci Paul cafe", "address": "上海市普陀区光复西路丹巴路28弄6号楼819" },
        { "value": "猫山王（西郊百联店）", "address": "上海市长宁区仙霞西路88号第一层G05-F01-1-306" },
        { "value": "枪会山", "address": "上海市普陀区棕榈路" },
        { "value": "纵食", "address": "元丰天山花园(东门) 双流路267号" },
        { "value": "钱记", "address": "上海市长宁区天山西路" },
        { "value": "壹杯加", "address": "上海市长宁区通协路" },
        { "value": "唦哇嘀咖", "address": "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元" },
        { "value": "爱茜茜里(西郊百联)", "address": "长宁区仙霞西路88号1305室" },
        { "value": "爱茜茜里(近铁广场)", "address": "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺" },
        { "value": "鲜果榨汁（金沙江路和美广店）", "address": "普陀区金沙江路2239号金沙和美广场B1-10-6" },
        { "value": "开心丽果（缤谷店）", "address": "上海市长宁区威宁路天山路341号" },
        { "value": "超级鸡车（丰庄路店）", "address": "上海市嘉定区丰庄路240号" },
        { "value": "妙生活果园（北新泾店）", "address": "长宁区新渔路144号" },
        { "value": "香宜度麻辣香锅", "address": "长宁区淞虹路148号" },
        { "value": "凡仔汉堡（老真北路店）", "address": "上海市普陀区老真北路160号" },
        { "value": "港式小铺", "address": "上海市长宁区金钟路968号15楼15-105室" },
        { "value": "蜀香源麻辣香锅（剑河路店）", "address": "剑河路443-1" },
        { "value": "北京饺子馆", "address": "长宁区北新泾街道天山西路490-1号" },
        { "value": "饭典*新简餐（凌空SOHO店）", "address": "上海市长宁区金钟路968号9号楼地下一层9-83室" },
        { "value": "焦耳·川式快餐（金钟路店）", "address": "上海市金钟路633号地下一层甲部" },
        { "value": "动力鸡车", "address": "长宁区仙霞西路299弄3号101B" },
        { "value": "浏阳蒸菜", "address": "天山西路430号" },
        { "value": "四海游龙（天山西路店）", "address": "上海市长宁区天山西路" },
        { "value": "樱花食堂（凌空店）", "address": "上海市长宁区金钟路968号15楼15-105室" },
        { "value": "壹分米客家传统调制米粉(天山店)", "address": "天山西路428号" },
        { "value": "福荣祥烧腊（平溪路店）", "address": "上海市长宁区协和路福泉路255弄57-73号" },
        { "value": "速记黄焖鸡米饭", "address": "上海市长宁区北新泾街道金钟路180号1层01号摊位" },
        { "value": "红辣椒麻辣烫", "address": "上海市长宁区天山西路492号" },
        { "value": "(小杨生煎)西郊百联餐厅", "address": "长宁区仙霞西路88号百联2楼" },
        { "value": "阳阳麻辣烫", "address": "天山西路389号" },
        { "value": "南拳妈妈龙虾盖浇饭", "address": "普陀区金沙江路1699号鑫乐惠美食广场A13" }
      ];
    },
    querySearchAsync(queryString, cb) {
      let that = this;
      $.ajax({
        url: this.$common.searchLearn,
        type: 'GET',
        data: {
          searchItem: queryString
        },
        dataType: 'text',
        contentType: 'application/x-www-form-urlencoded',
        success: function (res) {
          that.loading = false;
          let result = JSON.parse(res).message;
          that.blurSearchItem = result;
          console.log(result);
          // 此处调用 Element UI 的远程搜索，返回的值时对象，并且对象的属性名必须是 value，否则不认识并且不报错
          let arr = [];
          for (let i = 0; i < result.length; i ++) {
            arr[i] = {};
            arr[i].value = result[i].learntitle;
          }
          console.log(arr)
          // that.serverLearnList = result.message;
          // var results = result.message;
          // var results = queryString ? result.message.filter(that.createStateFilter(queryString)) : result.message;
          cb(arr);
        },
        error: function () {
          console.log('退出登录失败，无法连接到服务器，请稍后再试！')
        }
      })
    },
    handleSelect(item) {
      console.log(item);
      this.state = item.value;
      // console.log(this.state);
      this.clickSearch();
    },
    clickSearch () {
      const searchLoading = this.$loading({
        lock: true,
        text: '正在批阅经书，喝口茶休息一下先~',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
        // target:
      });
      let that = this;
      $.ajax({
        url: this.$common.searchLearn,
        type: 'GET',
        data: {
          searchItem: this.state
        },
        dataType: 'text',
        contentType: 'application/x-www-form-urlencoded',
        success: function (res) {
          searchLoading.close();
          that.loading = false;
          let result = JSON.parse(res).message;
          // that.blurSearchItem = result;
          console.log(result);
          for (let i = 0; i < result.length; i ++) {
            result[i].learncontent = JSON.parse(result[i].learncontent)
          }
          that.learnList = result;
          that.tempLearn = result[0];
          for (let j = 0; j < result[0].learncontent.length; j ++) {
            if (result[0].learncontent[j].addType === '图片链接') {
              that.tempPictureList.push(result[0].learncontent[j].value)
            } else {
              continue;
            }
          }
        },
        error: function () {
          searchLoading.close();
          console.log('退出登录失败，无法连接到服务器，请稍后再试！')
        }
      })
    }
  }
}
</script>

<style scoped>
.hello{
  width: 100%;
  height: calc(100% - 101px);
}
.searchLearn{
  width: 100%;
  height: 40px;
  margin-top: 15px;
}
.content{
  width: 90%;
  height: calc(100% - 30px);
  margin-left: 5%;
  padding-top: 30px;
}
.timeLine{
  padding: 10px;
  width: 40%;
  height: 100%;
  text-align: left;
  font-size: 35px;
  float: left;
  overflow-y: hidden;
}
.timeLine:hover{
  overflow-y: scroll;
}
.textOverflow{
  /* 展示两行文本，剩余的以省略号展示 */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: wrap;
  text-overflow: ellipsis;
  /* 首行缩进
  text-indent: 2em; */
}
.learnContent{
  width: 55%;
  height: 100%;
  margin-left: 5%;
  float: right;
  margin-top: -5px;
  overflow-y: hidden;
  text-align: left;
}
.learnContent:hover{
  overflow-y: scroll;
}
.changeLearn{
  height: 40px;
  overflow-y: clip;
}
.changeLearn:hover{
  cursor: pointer;
  text-decoration: underline;
}
.imgView{
  width: 600px;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -200px;
  z-index: 666;
  display: none;
}
.fullscreen{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.3;
  display: none;
}
.demo-image__preview >>> .el-image{
  overflow: visible;
}
</style>
