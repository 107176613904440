<script>
// 公共数据池
// const baseUrl = '/api/'
const baseUrl = 'https://blog.lxlgyy.cn/api/'
const login = baseUrl + 'login'
const signin = baseUrl + 'signin'
const nav = baseUrl + 'nav'
const uploadPicture = baseUrl + 'uploadPicture'
const uploadVideo = baseUrl + 'uploadVideo'
const uploadFile = baseUrl + 'uploadFile'
const uploadLearn = baseUrl + 'uploadLearn'
const uploadHomeworkFile = baseUrl + 'uploadHomeworkFile'
const uploadHomework = baseUrl + 'uploadHomework'
const queryLearn = baseUrl + 'queryLearn'
const queryHomework = baseUrl + 'queryHomework'
const queryVerify = baseUrl + 'queryVerify'
const queryKindKnowledge = baseUrl + 'queryKindKnowledge'
const searchLearn = baseUrl + 'searchLearn'
export default {
  login,
  signin,
  nav,
  uploadPicture,
  uploadVideo,
  uploadFile,
  uploadLearn,
  uploadHomeworkFile,
  queryLearn,
  uploadHomework,
  queryHomework,
  queryVerify,
  queryKindKnowledge,
  searchLearn
}
</script>
